import { post, get } from "./api.service";

const ReportService = {
    hours(params) {
        return post("/admin/reports/hours", params);
    },

    formCompletion(params) {
        return post("/admin/reports/forms/completions", params);
    },
    trainingCompletion(params) {
        return get("/admin/training/enrollment/search", params);
    },
};

export { ReportService };
